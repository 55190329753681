<template>
  <div class="login d-flex justify-center">
  </div>
</template>
<script>
import { LSS } from '@core/services';
import { API_ORY_URL } from '@config'
  export default {
    name: 'Logout',
    data () {
      return {
        logged : false
      }
    },
    methods: {
      logout() {
        //console.log('logout');
      }
    },
    mounted() {
      LSS.clear();
      window.location.href = API_ORY_URL + 'self-service/browser/flows/logout'
    }
  }
</script>
